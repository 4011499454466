import { SetState } from '../types/functions';
import { MandateObjModel } from './mandateModel';
import { DocModel } from './userModel';

export interface UserOnboardingModel {
  name: string;
  email: string;
}

export interface UserRegistrationModel {
  id?: string;
  registrationStep?: string;
  residence?: OnboardingResidenceModel;
  taxCode?: string;
  birthDate?: Date;
  birthProvince?: string;
  birthPlace?: string;
  atecos?: string[];
  cassaPrevidenziale?: string;
  idDoc?: DocModel;
  taxDoc?: DocModel;
  electronicInvoicing?: MandateObjModel;
  fiscalDrawer?: MandateObjModel;
  youSignSignatureRequestId?: string;
  vat?: string;
  vatOpeningDate?: Date;
  promoCode?: string;
}

export interface OnboardingResidenceModel {
  address?: string;
  cap?: string;
  city?: string;
  province?: string;
  region?: string;
  regionCode?: string;
  codCatastale?: string;
}

export interface RegisterOnboardingModel {
  email: string;
  password: string;
  privacy: boolean;
  terms: boolean;
  marketing?: boolean;
  crmId?: string;
}

export interface ActivationOnboardingModel {
  lastCompletedStatus: number;
  previousVatStatus: string;
  status: StatusOnboardingModel;
  statusList: StatusListOnboardingModel[];
}

export interface StatusListOnboardingModel {
  status: StatusOnboardingModel;
  completedAt?: Date;
}

export enum StatusOnboardingModel {
  REGISTRATION_COMPLETED = "registration_completed",
  VERIFYING_ACCOUNT = 'verifying_account',
  VAT_TO_OPEN = 'vat_to_open',
  OPENING_VAT = 'opening_vat',
  VAT_OPENED = 'vat_opened',
  ACCOUNT_VERIFIED = 'account_verified',
  CHECK_NOT_OK = 'check_not_ok',
  TERMINATION = 'termination',
  NOT_MANAGEABLE = 'not_manageable',
  DESERTION = 'desertion',
}

export interface OnboardingPersonalInfoErrorSetters {
  setErrorAddress: SetState<boolean>;
  setErrorCity: SetState<boolean>;
  setErrorZipCode: SetState<boolean>;
  setErrorProvince: SetState<boolean>;
  setErrorTaxCode: SetState<boolean>;
  setErrorDateOfBirth: SetState<boolean>;
  setErrorBirthPlace: SetState<boolean>;
  setErrorBirthProvince: SetState<boolean>;
}

export interface OnboardingFiscalInfoErrorSetters {
  setErrorAtecos: SetState<boolean>;
  setErrorVat: SetState<boolean>;
}

export interface OnboardingDocumentsErrorSetters {
  setErrorDocIdType: SetState<boolean>;
  setErrorDocIdNumber: SetState<boolean>;
  setErrorDocIdReleasedBy: SetState<boolean>;
  setErrorDocIdReleasedDate: SetState<boolean>;
  setErrorDocIdExpirationDate: SetState<boolean>;
  setErrorIdDocFileId: SetState<boolean>;
  setErrorTaxDocFileId: SetState<boolean>;
}