import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import config from '../../../config.json';
import lang from './subscriptionExpired.json';
import './subscriptionExpired.css';

interface Props {
  open: boolean;
  setOpen: Function;
}

export function SubscriptionExpired(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  return (
    <QuickfiscoModal hide={() => setOpen(false)} isOpen={open}>
      <div className={'row'}>
        <div className={'col-12 text-center'}>
          <div className={'row'}>
            <div className={'col-12 trial-expired-title'}>{lang.title}</div>
          </div>
          <div className={'row my-3'}>
            <div className={'col-12 trial-expired-sub-title'}>
              {lang.subTitle}
            </div>
          </div>
          <div className={'row d-flex justify-content-center'}>
            <div className={'col-12 trial-expired-text'}>{lang.text}</div>
          </div>
          <div
            className={
              'row d-flex justify-content-center align-items-center my-5'
            }
          >
            <div className={'col-5'}>
              <a href={config.calendly} target={'_blank'} rel={'noreferrer'}>
                <QuickfiscoButton
                  id={'trial-expired-button'}
                  label={lang.renewButton}
                  type={'secondary'}
                />
              </a>
            </div>
          </div>
          <div className={'row d-flex justify-content-center mb-5'}>
            <div className={'col-8 trial-expired-text'}>
              {lang.text2}{' '}
              <a
                href={`mailto:info@quickfisco.it`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                info@quickfisco.it
              </a>
            </div>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}
