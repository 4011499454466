import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import { CrmIntegrationService } from '../../services/crmIntegrationService';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoProvinceSelect } from '../../components/quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import { QuickfiscoDatePicker } from '../../components/quickfiscoDatePicker/quickfiscoDatePicker';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { QuickfiscoCitySelect } from '../../components/quickfiscoCitySelect/quickfiscoCitySelect';
import { useEffect, useState } from 'react';
import { UserRegistrationModel } from '../../models/onboardingModel';
import { UserService } from '../../services/userService';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { getMe } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './personalInfoOnboarding.json';
import './personalInfoOnboarding.css';

export function PersonalInfoOnboarding() {
  const [city, setCity] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [zipCode, setZipCode] = useState<string | undefined>(undefined);
  const [province, setProvince] = useState<string | undefined>(undefined);
  const [region, setRegion] = useState<string | undefined>(undefined);
  const [regionCode, setRegionCode] = useState<string | undefined>(undefined);
  const [codCatastale, setCodCatastale] = useState<string | undefined>(
    undefined
  );
  const [taxCode, setTaxCode] = useState<string | undefined>(undefined);
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined);
  const [birthPlace, setBirthPlace] = useState<string | undefined>(undefined);
  const [birthProvince, setBirthProvince] = useState<string | undefined>(
    undefined
  );
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);

  const [editStatus, setEditStatus] = useState<PromiseStatuses>('idle');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onboardingState = useAppSelector((state) => state.onboarding);
  const user = useAppSelector((state) => state.user.editUserRequest);
  const status = useAppSelector((state) => state.user.status);

  const crmIntegrationService = new CrmIntegrationService(dispatch);

  const dataToSend: UserRegistrationModel = {
    id: user.id,
    residence: {
      address,
      cap: zipCode,
      city,
      province,
      region,
      regionCode,
      codCatastale,
    },
    taxCode,
    birthDate: dateOfBirth,
    birthProvince,
    birthPlace,
    promoCode,
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    setCity(user.city);
    setAddress(user.address);
    setZipCode(user.zipCode);
    setProvince(user.province);
    setTaxCode(user.taxCode);
    user.dateOfBirth && setDateOfBirth(new Date(user.dateOfBirth));
    setBirthPlace(user.birthPlace);
    setBirthProvince(user.birthProvince);
    setPromoCode(user.promoCode);
  }, [user]);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || editStatus === 'failed') {
    return (
      <OnboardingApiError
        statusFailed={status === 'failed' || editStatus === 'failed'}
      />
    );
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="personal-info-onboarding-main-title">
                01. <span>{lang.title}</span>
              </div>
              <div className="personal-info-onboarding-subtitle">
                {lang.subTitle1}
                <br />
                {lang.subTitle2}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-8 offset-2 personal-info-onboarding-container box-shadow py-5'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-10 offset-1'}>
                  <div className="row no-gutters">
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-name"
                        label={lang.NameLabel}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.NamePlaceholder}
                        value={user.name}
                        required={true}
                        disabled={true}
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-surname"
                        label={lang.SurnameLabel}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.SurnamePlaceholder}
                        value={user.surname}
                        required={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-email"
                        label={lang.EmailLabel}
                        styleType={'default'}
                        type="email"
                        placeholder={lang.EmailPlaceholder}
                        value={user.email}
                        required={true}
                        disabled={true}
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-phone"
                        label={lang.PhoneLabel}
                        styleType={'default'}
                        type="text"
                        placeholder={lang.PhonePlaceholder}
                        value={user.phone}
                        required={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters'}>
                    <div
                      className={'col personal-info-onboarding-section-title'}
                    >
                      {lang.AddressData}
                    </div>
                  </div>
                  <div className="row no-gutters mt-3">
                    <div className="col-6">
                      <QuickfiscoCitySelect
                        id="personal-info-onboarding-city"
                        value={city || ''}
                        placeholder={lang.CityPlaceholder}
                        label={lang.CityLabel}
                        onChange={(e) => {
                          setCity(e.value);
                          // @ts-ignore
                          setZipCode(e.cityZipCode);
                          // @ts-ignore
                          setProvince(e.cityProvince);
                          // @ts-ignore
                          setRegion(e.addressExtra.cityRegion);
                          // @ts-ignore
                          setRegionCode(e.addressExtra.cityRegionCode);
                          // @ts-ignore
                          setCodCatastale(e.addressExtra.cityCodCatastale);
                        }}
                        error={onboardingState.errorCity}
                        errorLabel={lang.ErrorCity}
                        requiredField={true}
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-address"
                        label={lang.AddressLabel}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.AddressPlaceholder}
                        onChange={(e) => setAddress(e.target.value)}
                        value={address || ''}
                        error={onboardingState.errorAddress}
                        errorLabel={lang.ErrorAddress}
                        required={true}
                        onBlur={(e) =>
                          crmIntegrationService.validateAddress(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-2">
                      <QuickfiscoInput
                        id="personal-info-onboarding-zipCode"
                        label={lang.ZipCodeLabel}
                        type="text"
                        styleType={'default'}
                        placeholder={lang.ZipCodePlaceholder}
                        onChange={(e) => setZipCode(e.target.value)}
                        value={zipCode || ''}
                        error={onboardingState.errorZipCode}
                        errorLabel={lang.ErrorZipCode}
                        required={true}
                        maxLength={5}
                        onBlur={(e) =>
                          crmIntegrationService.validateZipCode(e.target.value)
                        }
                      />
                    </div>
                    <div className="col-4">
                      <QuickfiscoProvinceSelect
                        id={'personal-info-onboarding-province'}
                        value={province || undefined}
                        onChange={(value) => value && setProvince(value)}
                        error={onboardingState.errorProvince}
                        errorLabel={lang.ErrorProvince}
                        requiredField={true}
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters'}>
                    <div
                      className={'col personal-info-onboarding-section-title'}
                    >
                      {lang.PersonalData}
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-tax-code"
                        label={lang.TaxCodeLabel}
                        styleType={'default'}
                        type="text"
                        placeholder={lang.TaxCodePlaceholder}
                        onChange={(e) =>
                          setTaxCode(e.target.value.toUpperCase())
                        }
                        value={taxCode || ''}
                        error={onboardingState.errorTaxCode}
                        errorLabel={lang.ErrorTaxCode}
                        required={true}
                        onBlur={(e) =>
                          crmIntegrationService.validateTaxCode(e.target.value)
                        }
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoDatePicker
                        id="personal-info-onboarding-date-of-birth"
                        label={lang.DateOfBirth}
                        required={true}
                        styleType={'default'}
                        value={dateOfBirth ? new Date(dateOfBirth) : undefined}
                        maxDate={new Date()}
                        onChange={(e) =>
                          e &&
                          setDateOfBirth(resolveUndefinedDate(formatDateUS(e)))
                        }
                        error={onboardingState.errorDateOfBirth}
                        errorLabel={lang.ErrorDateOfBirth}
                      />
                    </div>
                  </div>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-6">
                      <QuickfiscoInput
                        id="personal-info-onboarding-birth-place"
                        label={lang.BirthPlace}
                        type="text"
                        placeholder={lang.BirthPlacePlaceholder}
                        onChange={(e) => setBirthPlace(e.target.value)}
                        value={birthPlace || ''}
                        error={onboardingState.errorBirthPlace}
                        errorLabel={lang.ErrorBirthPlace}
                        styleType={'default'}
                        required={true}
                        onBlur={(e) =>
                          crmIntegrationService.validateBirthPlace(
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-6">
                      <QuickfiscoProvinceSelect
                        id={'personal-info-onboarding-birth-province'}
                        value={birthProvince}
                        label={lang.BirthProvince}
                        onChange={(value) => {
                          value && setBirthProvince(value);
                          value &&
                            crmIntegrationService.validateBirthProvince(value);
                        }}
                        error={onboardingState.errorBirthProvince}
                        errorLabel={lang.ErrorBirthProvince}
                        requiredField={true}
                      />
                      <div className="personal-info-onboarding-birth-province-text mt-2">
                        <QuickfiscoIcon
                          name={'alert-triangle-blue-small.svg'}
                          className="me-2"
                        />
                        {lang.provinceInfo}
                      </div>
                    </div>
                  </div>
                  <div className={'row mt-4 pt-2'}>
                    <div className="col-12">
                      <div className="personal-info-onboarding-promo-code-container px-3 py-4">
                        <div
                          className={'row no-gutters d-flex align-items-center'}
                        >
                          <div className="col-auto">
                            <QuickfiscoIcon
                              name={'onboarding-promo-code.svg'}
                            />
                          </div>
                          <div className="col">
                            <div className="personal-info-onboarding-promo-code-title">
                              Hai un codice “<span>amico</span>”?
                            </div>
                            <div className="personal-info-onboarding-promo-code-text">
                              Inseriscilo per ottenere il tuo voucher
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="personal-info-onboarding-promocode-container">
                              <QuickfiscoInput
                                id={'personal-info-onboarding-promocode'}
                                type={'text'}
                                styleType={'no-borders'}
                                placeholder={lang.promocodePlaceholder}
                                onChange={(e) => setPromoCode(e.target.value)}
                                value={promoCode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-4 offset-8 d-flex justify-content-center align-items-center">
                      {editStatus === 'loading' ? (
                        <QuickfiscoSpinner />
                      ) : (
                        <QuickfiscoButton
                          type={'secondary'}
                          id="personal-info-onboarding-edit"
                          label={lang.ButtonLabel}
                          onClick={() =>
                            update(
                              dataToSend,
                              dispatch,
                              navigate,
                              setEditStatus
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function update(
  user: UserRegistrationModel,
  dispatch: Function,
  navigate: NavigateFunction,
  setStatus: SetState<PromiseStatuses>
) {
  if (user.id) {
    const crmIntegrationService = new CrmIntegrationService(dispatch);

    if (!(await crmIntegrationService.validatePersonalInfo(user))) {
      return;
    }

    const userService = new UserService();

    setStatus('loading');

    userService
      .newUpdateUser({ ...user, registrationStep: 'profile_registry' })
      .then(() => {
        navigate(uri.FiscalInfo);
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }
}
