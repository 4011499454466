import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangePasswordModel } from '../../models/changePasswordModel';
import { AddressExtraModel, BankAccount, UserModel } from '../../models/userModel';
import { UserService } from '../../services/userService';
import { PromiseStatuses } from '../../types/strings';

interface UserState {
  user: UserModel;
  editUserRequest: UserModel;
  changePasswordRequest: ChangePasswordModel,
  status: PromiseStatuses;
  editStatus: PromiseStatuses;
  changePasswordStatus: PromiseStatuses;
  sendIntegrationDdrStatus: PromiseStatuses;
  profileNotSavedModalIsOpen: boolean;
  errorName: boolean;
  errorSurname: boolean;
  errorPhone: boolean,
  errorVat: boolean;
  errorAddress: boolean;
  errorCity: boolean;
  errorZipCode: boolean;
  errorProvince: boolean;
  errorTaxCode: boolean;
  errorPec: boolean;
  errorFund: boolean;
  errorBankName: boolean;
  errorBankIban: boolean;
  errorInitialInvoiceNumber: boolean;
  errorAteco: boolean;
  errorOldPassword: boolean;
  errorNewPassword: boolean;
  errorConfirmPassword: boolean;
  errorDateOfBirth?: boolean;
  errorBirthPlace?: boolean;
  errorBirthProvince?: boolean;
  errorGender?: boolean;
  errorVatOpeningDate?: boolean;
}

const initialState: UserState = {
  status: 'idle',
  user: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    privacy: true,
    terms: true,
    atecos: [],
    bankAccount: []
  },
  editUserRequest: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    privacy: true,
    terms: true,
    atecos: [],
    marketing: false,
    bankAccount: []
  },
  changePasswordRequest: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  },
  editStatus: 'idle',
  changePasswordStatus: 'idle',
  sendIntegrationDdrStatus: 'idle',
  profileNotSavedModalIsOpen: false,
  errorName: false,
  errorSurname: false,
  errorPhone: false,
  errorVat: false,
  errorAddress: false,
  errorCity: false,
  errorZipCode: false,
  errorProvince: false,
  errorTaxCode: false,
  errorPec: false,
  errorFund: false,
  errorBankName: false,
  errorBankIban: false,
  errorInitialInvoiceNumber: false,
  errorAteco: false,
  errorOldPassword: false,
  errorNewPassword: false,
  errorConfirmPassword: false
};

export const getMe = createAsyncThunk(
  'user/getMe',
  async (): Promise<UserModel> => {
    const service = new UserService();
    return service.getMe();
  }
);

export const updateMe = createAsyncThunk(
  'user/updateMe',
  async (request: UserModel): Promise<void> => {
    return new UserService()
      .updateMe(request)
      .catch(err => err.json().then((data: any) => Promise.reject(data.message)))
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (request: ChangePasswordModel): Promise<void> => {
    return new UserService()
      .changePassword(request)
      .catch(err => err.json().then((data: any) => Promise.reject(data.message)))
  }
);

export const sendIntegrationDdrEmail = createAsyncThunk(
  'user/ddrEmail',
  async (): Promise<void> => {
    return new UserService()
      .sendIntegrationDdrEmail()
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.editUserRequest.name = action.payload
    },
    setSurname: (state, action: PayloadAction<string>) => {
      state.editUserRequest.surname = action.payload
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.editUserRequest.phone = action.payload
    },
    setAddress: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.address = action.payload
    },
    setZipCode: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.zipCode = action.payload
    },
    setCity: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.city = action.payload
    },
    setProvince: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.province = action.payload
    },
    setAddressExtra: (state, action: PayloadAction<AddressExtraModel>) => {
      state.editUserRequest.addressExtra = action.payload;
    },
    setPec: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.pec = action.payload
    },
    setBirthPlace: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.birthPlace = action.payload
    },
    setBirthProvince: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.birthProvince = action.payload
    },
    setDateOfBirth: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.dateOfBirth = action.payload
    },
    setGender: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.gender = action.payload
    },
    setBankAccount: (state, action: PayloadAction<BankAccount[]>) => {
      state.editUserRequest.bankAccount = action.payload;
    },
    setAtecos: (state, action: PayloadAction<string[]>) => {
      state.editUserRequest.atecos = action.payload
    },
    setTaxCode: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.taxCode = action.payload
    },
    setVat: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.vat = action.payload
    },
    setFund: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.fund = action.payload
    },
    setRecourseValue: (state, action: PayloadAction<number | undefined>) => {
      state.editUserRequest.recourseValue = action.payload
    },
    setContributionReduction: (state, action: PayloadAction<boolean | undefined>) => {
      state.editUserRequest.contributionReduction = action.payload
    },
    setInitialInvoiceNumber: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.initialInvoiceNumber = action.payload
    },
    setInitialInvoiceNumberSTS: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.initialInvoiceNumberSTS = action.payload
    },
    setVatOpeningDate: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.vatOpeningDate = action.payload
    },
    setErrorName: (state, action: PayloadAction<boolean>) => {
      state.errorName = action.payload
    },
    setErrorSurname: (state, action: PayloadAction<boolean>) => {
      state.errorSurname = action.payload
    },
    setErrorPhone: (state, action: PayloadAction<boolean>) => {
      state.errorPhone = action.payload
    },
    setErrorVat: (state, action: PayloadAction<boolean>) => {
      state.errorVat = action.payload
    },
    setErrorVatOpeningDate: (state, action: PayloadAction<boolean>) => {
      state.errorVatOpeningDate = action.payload
    },
    setErrorAddress: (state, action: PayloadAction<boolean>) => {
      state.errorAddress = action.payload
    },
    setErrorCity: (state, action: PayloadAction<boolean>) => {
      state.errorCity = action.payload
    },
    setErrorZipCode: (state, action: PayloadAction<boolean>) => {
      state.errorZipCode = action.payload
    },
    setErrorProvince: (state, action: PayloadAction<boolean>) => {
      state.errorProvince = action.payload
    },
    setErrorTaxCode: (state, action: PayloadAction<boolean>) => {
      state.errorTaxCode = action.payload
    },
    setErrorPec: (state, action: PayloadAction<boolean>) => {
      state.errorPec = action.payload
    },
    setErrorFund: (state, action: PayloadAction<boolean>) => {
      state.errorFund = action.payload
    },
    setErrorBankName: (state, action: PayloadAction<boolean>) => {
      state.errorBankName = action.payload
    },
    setErrorBankIban: (state, action: PayloadAction<boolean>) => {
      state.errorBankIban = action.payload
    },
    setErrorDateOfBirth: (state, action: PayloadAction<boolean>) => {
      state.errorDateOfBirth = action.payload
    },
    setErrorBirthPlace: (state, action: PayloadAction<boolean>) => {
      state.errorBirthPlace = action.payload
    },
    setErrorBirthProvince: (state, action: PayloadAction<boolean>) => {
      state.errorBirthProvince = action.payload
    },
    setErrorGender: (state, action: PayloadAction<boolean>) => {
      state.errorGender = action.payload
    },
    setErrorInitialInvoiceNumber: (state, action: PayloadAction<boolean>) => {
      state.errorInitialInvoiceNumber = action.payload
    },
    setErrorAteco: (state, action: PayloadAction<boolean>) => {
      state.errorAteco = action.payload
    },
    setProfileEditStatus: (state, action: PayloadAction<PromiseStatuses>) => {
      state.editStatus = action.payload
    },
    setOldPassword: (state, action: PayloadAction<string>) => {
      state.changePasswordRequest.oldPassword = action.payload
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.changePasswordRequest.newPassword = action.payload
    },
    setConfirmPassword: (state, action: PayloadAction<string>) => {
      state.changePasswordRequest.confirmPassword = action.payload
    },
    setErrorOldPassword: (state, action: PayloadAction<boolean>) => {
      state.errorOldPassword = action.payload
    },
    setErrorNewPassword: (state, action: PayloadAction<boolean>) => {
      state.errorNewPassword = action.payload
    },
    setErrorConfirmPassword: (state, action: PayloadAction<boolean>) => {
      state.errorConfirmPassword = action.payload
    },
    setChangePasswordStatus: (state, action: PayloadAction<PromiseStatuses>) => {
      state.changePasswordStatus = action.payload
    },
    setProfileNotSavedModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.profileNotSavedModalIsOpen = action.payload
    },
    setSendIntegrationDdrEmailStatus: (state, action: PayloadAction<PromiseStatuses>) => {
      state.changePasswordStatus = action.payload
    },
    setMarketing: (state, action: PayloadAction<boolean | undefined>) => {
      state.editUserRequest.marketing = action.payload
    },
    setSts: (state, action: PayloadAction<boolean | undefined>) => {
      state.editUserRequest.stsEnabled = action.payload
    },
    setCompanyName: (state, action: PayloadAction<string | undefined>) => {
      state.editUserRequest.companyName = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.status = 'successfully';
        state.user = action.payload;
        state.editUserRequest = action.payload;
      })
      .addCase(getMe.rejected, ((state) => {
        state.status = 'failed';
      }))
      .addCase(updateMe.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(updateMe.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.editStatus = 'failed';
        if (action.error.message === 'vat already exists') {
          state.errorVat = true
        }
      })
      .addCase(changePassword.pending, (state) => {
        state.changePasswordStatus = 'loading';
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordStatus = 'successfully';
        state.changePasswordRequest.oldPassword = '';
        state.changePasswordRequest.newPassword = '';
        state.changePasswordRequest.confirmPassword = '';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordStatus = 'failed';
        if (action.error.message === 'old password is not valid') {
          state.errorOldPassword = true
        }
      })
      .addCase(sendIntegrationDdrEmail.pending, (state) => {
        state.sendIntegrationDdrStatus = 'loading';
      })
      .addCase(sendIntegrationDdrEmail.fulfilled, (state) => {
        state.sendIntegrationDdrStatus = 'successfully';
      })
      .addCase(sendIntegrationDdrEmail.rejected, ((state) => {
        state.sendIntegrationDdrStatus = 'failed';
      }))
  }
});

export const {
  setName,
  setSurname,
  setPhone,
  setAddress,
  setZipCode,
  setCity,
  setProvince,
  setAddressExtra,
  setPec,
  setBirthPlace,
  setBirthProvince,
  setDateOfBirth,
  setGender,
  setBankAccount,
  setAtecos,
  setTaxCode,
  setVat,
  setFund,
  setRecourseValue,
  setContributionReduction,
  setInitialInvoiceNumber,
  setVatOpeningDate,
  setErrorName,
  setErrorSurname,
  setErrorPhone,
  setErrorAddress,
  setErrorAteco,
  setErrorBankName,
  setErrorBankIban,
  setErrorDateOfBirth,
  setErrorBirthPlace,
  setErrorBirthProvince,
  setErrorGender,
  setErrorCity,
  setErrorFund,
  setErrorInitialInvoiceNumber,
  setInitialInvoiceNumberSTS,
  setErrorPec,
  setErrorProvince,
  setErrorTaxCode,
  setErrorVat,
  setErrorVatOpeningDate,
  setErrorZipCode,
  setProfileEditStatus,
  setConfirmPassword,
  setErrorConfirmPassword,
  setErrorNewPassword,
  setErrorOldPassword,
  setNewPassword,
  setOldPassword,
  setChangePasswordStatus,
  setProfileNotSavedModalIsOpen,
  setSendIntegrationDdrEmailStatus,
  setMarketing,
  setSts,
  setCompanyName
} = userSlice.actions

export default userSlice.reducer;
