import { useState } from 'react';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ObjectService } from '../../services/objectService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './documentsPreviewModal.json';
import './documentsPreviewModal.css';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  docId?: string;
}

export function DocumentsPreviewModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { docId } = props;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1.0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2.0));
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  return (
    <QuickfiscoModal
      modalClassName={'documents-preview-modal-component'}
      isOpen={open}
      hide={() => setOpen(false)}
      size="lg"
    >
      <div className={'row'}>
        <div
          className={
            'col-10 offset-1 text-center documents-preview-modal-title'
          }
        >
          {lang.modalTitle}
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-12 d-flex justify-content-center'}>
          {lang.zoom}
          <QuickfiscoIcon
            name={'zoom-out.svg'}
            onClick={zoomOut}
            className="ms-2"
          />
          <QuickfiscoIcon
            name={'zoom-in.svg'}
            onClick={zoomIn}
            className="ms-2"
          />
        </div>
      </div>
      <div className={'row my-3'}>
        <div className={'col-10 offset-1 text-center border-gray'}>
          <div className="documents-preview-modal-render-file d-flex justify-content-center align-items-center my-4">
            {docId ? (
              <Document
                file={getFileUrl(docId)}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={() => {
                  return (
                    <div
                      className={
                        'w-100 h-100 d-flex justify-content-center align-items-center'
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  );
                }}
                error={lang.previewPdfError}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={scale}
                  />
                ))}
              </Document>
            ) : (
              <div>{lang.previewPdfError}</div>
            )}
          </div>
        </div>
      </div>
      <div className={'row mb-5'}>
        <div className={'col-4 offset-4 text-left'}>
          <QuickfiscoButton
            id={'documents-modal-download-pdf'}
            label={lang.close}
            type={'primary'}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function getFileUrl(documentId: string): string {
  const service = new ObjectService();
  return service.getFileUrl(documentId);
}
